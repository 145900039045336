<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="spinning">
			<!-- 标题 -->
			<div class="modal-header">
				<div class="modal-title">
					<a-icon type="medicine-box" />
					<span v-if="id">{{ l('编辑请假明细') }}</span>
					<span v-else>{{ l('新增请假明细') }}</span>
				</div>
			</div>
			<div class="my-md">
				<a-row>
					<a-col :span="4">
						<q-label required :label="l('LeaveStartTime')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-date-picker
							style="width: 100%"
							format="YYYY-MM-DD HH:mm"
							valueFormat="YYYY-MM-DD HH:mm"
							:showTime="{ format: 'HH:mm' }"
							v-model="entity.startTime"
						/>
					</a-col>
					<a-col :span="4">
						<q-label required :label="l('LeaveEndTime')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-date-picker
							style="width: 100%"
							format="YYYY-MM-DD HH:mm"
							valueFormat="YYYY-MM-DD HH:mm"
							:showTime="{ format: 'HH:mm' }"
							v-model="entity.endTime"
						/>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label required :label="l('LeaveTimeNumber')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number
							:placeholder="l('LeaveTimeNumber')"
							v-model="entity.leaveNums"
							style="width: 100%"
							:min="0"
						/>
					</a-col>
					<a-col :span="4">
						<q-label required :label="l('LeaveType')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-select
							show-search
							:placeholder="l('休假类型')"
							option-filter-prop="children"
							style="width: 100%"
							v-model="entity.leaveType"
						>
							<a-select-option v-for="item in LeaveTypeList" :key="item.code">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('Remark')"> </q-label>
					</a-col>
					<a-col :span="20">
						<a-textarea :placeholder="l('Remark')" :rows="4" v-model="entity.reason" />
					</a-col>
				</a-row>
			</div>
			<!-- 按钮 -->
			<div class="modal-footer">
				<a-button @click="close" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :type="'primary'" @click="save">
					<a-icon type="save" />
					{{ l('Save') }}
				</a-button>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import ModalComponentBase from '../../../../../../shared/component-base/modal-component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import {
	CreateOrUpdateLeaveRecordInput,
	LeaveRecordEditDto,
	LeaveRecordServiceProxy,
} from '../../../../../../shared/service-proxies';
import moment from 'moment';

export default {
	name: 'create-or-edit-leave-record',
	mixins: [ModalComponentBase],
	data() {
		return {
			spinning: false,
			zh_CN,
			id: undefined,
			LeaveTypeList: [],
			entity: new LeaveRecordEditDto(),
			userId: undefined,
		};
	},
	created() {
		this.fullData();
		this._leaveRecordServiceProxy = new LeaveRecordServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		this.LeaveTypeList = await this.getLeaveType();
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		getData() {
			this.spinning = true;
			this._leaveRecordServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.entity = res.leaveRecord;
				});
		},
		/**
		 * 获取请假类型
		 */
		getLeaveType() {
			return new Promise((resolve, reject) => {
				this.spinning = true;
				this._leaveRecordServiceProxy
					.getHolidayRuleList()
					.finally(() => {
						this.spinning = false;
					})
					.then((res) => {
						resolve(res);
					});
			});
		},
		save() {
			if (!this.entity.startTime) {
				return abp.message.warn('请选择开始日期');
			}
			if (!this.entity.endTime) {
				return abp.message.warn('请选择结束日期');
			}

			this.entity.startTime = moment(this.entity.startTime);
			this.entity.endTime = moment(this.entity.endTime);
			if (this.entity.startTime > this.entity.endTime) {
				return abp.message.warn('开始日期不能大于结束日期');
			}
			if (this.entity.leaveNums != 0 && !this.entity.leaveNums) {
				return abp.message.warn('请输入请假时数');
			}
			if (this.entity.leaveNums <= 0) {
				return abp.message.warn('请假时数必须大于0');
			}
			if (!this.entity.leaveType) {
				return abp.message.warn('请选择请假类型');
			}
			// if(this.entity.leaveType!='NJ'){
			//     this.entity.leaveNums=0;
			// }
			this.entity.empId = this.userId;
			let input = new CreateOrUpdateLeaveRecordInput();
			input.leaveRecord = this.entity;
			this.spinning = true;
			this._leaveRecordServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped></style>
