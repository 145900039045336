<template>
    <a-config-provider :locale="zh_CN">
        <a-card>
            <a-row :gutter="[10,10]">
                <a-col :span="4">
                    <a-tree-select
                            :placeholder="l('Company')"
                            @change="handleChange"
                            style="width: 100%"
                            tree-default-expand-all
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            :tree-data="companyTreeList"
                            v-model="comPanyId"
                    />
                </a-col>
                <a-col :span="4">
                    <a-tree-select
                            v-model="departmentId"
                            allowClear
                            style="width: 100%"
                            show-search
                            :filterTreeNode="searchTree"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            :tree-data="DepTreeData"
                            :placeholder="l('Department')"
                            @change="getUserData"
                            tree-default-expand-all>
                    </a-tree-select>
                </a-col>
                <a-col :span="5" offset="11" style="text-align: right">
                    <a-input-search
                            style="width: 200px;"
                            name="filterText"
                            :placeholder="l('SearchWithThreeDot')"
                            @search="fileChange"
                            enterButton
                            v-model="filterText"
                    />
                </a-col>
            </a-row>
            <a-row :gutter="[10,10]">
                <a-col :span="24">
                    <a-spin :spinning="spinning">
                        <div class="my-md">
                            <a-table
                                    :scroll="{y:scroll_y}"
                                    @change="sorterChange"
                                    :columns="columns"
                                    :customRow="customRow"
                                    :pagination="false"
                                    :rowKey="data => data.id"
                                    :dataSource="data">
                                <span slot="jobNumber" slot-scope="text,record">
                                    <a>{{record.jobNumber}}</a>
                                </span>
                            </a-table>
                            <a-pagination
                                    class="pagination"
                                    :total="totalItems"
                                    showSizeChanger
                                    v-model="pageNumber"
                                    showQuickJumper
                                    :showTotal="showTotalFun"
                                    @change="onChange"
                                    @showSizeChange="showSizeChange"
                            />
                        </div>

                    </a-spin>
                </a-col>
            </a-row>
        </a-card>
    </a-config-provider>
</template>

<script>
    import {AppComponentBase} from "../../../shared/component-base";
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
    import {
        CompanyServiceProxy, DepartmentServiceProxy,
        OrganizationUnitServiceProxy,
        SignCardRecordServiceProxy,
        UserServiceProxy
    } from "../../../shared/service-proxies";
    import * as _ from "lodash";
    import moment from 'moment'
    import ModalHelper from '../../../shared/helpers/modal/modal-helper'
    import LeaveCardOvertimeDetailedPage from './leave-card-overtime-detailed-page/leave-card-overtime-detailed-page'
    import {abpService} from "../../../shared/abp";
    import DepTreeUntils from "../../organization/department/dep-tree-untils/dep-tree-untils";

    let _this;
    export default {
        name: "leave-card-overtime-detailed",
        mixins: [AppComponentBase],
        components: {LeaveCardOvertimeDetailedPage},
        data() {
            return {
                spinning: false,
                //表格上方间隔
                defaultTop: 80,
                zh_CN,
                companyTreeList: [],
                comPanyId: undefined,
                departmentId: undefined,
                //部门树
                DepTreeData: [],
                DepTree: [],
                filterText: undefined,
                // 总数
                totalItems: 0,
                // 当前页码
                pageNumber: 1,
                // 共多少页
                totalPages: 1,
                // 条数显示范围
                pagerange: [1, 1],
                // 显示条数
                pageSizeOptions: ["10", "20", "30", "40"],
                request: {maxResultCount: 10, skipCount: 0},
                // 用户表格
                columns: [
                    {
                        title: this.l("JobNumber"),
                        dataIndex: "jobNumber",
                        sorter: true,
                        ellipsis: true,
                        align: "center",
                        scopedSlots: {customRender: "jobNumber"}
                    },
                    {
                        title: this.l("RealName"),
                        dataIndex: "realName",
                        sorter: true,
                        ellipsis: true,
                        align: "center",
                        scopedSlots: {customRender: "realName"}
                    },
                    {
                        title: this.l("Company"),
                        dataIndex: "companyName",
                        sorter: true,
                        ellipsis: true,
                        align: "center",
                        scopedSlots: {customRender: "companyName"}
                    },
                    {
                        title: this.l("Department"),
                        dataIndex: "departmentName",
                        sorter: true,
                        ellipsis: true,
                        align: "center",
                        scopedSlots: {customRender: "departmentName"}
                    },
                    {
                        title: this.l("EmpType"),
                        dataIndex: "employeeType",
                        sorter: true,
                        ellipsis: true,
                        align: "center",
                        scopedSlots: {customRender: "employeeType"}
                    },
                    {
                        title: this.l("HireDate"),
                        dataIndex: "hireDate",
                        sorter: true,
                        ellipsis: true,
                        align: "center",
                        scopedSlots: {customRender: "hireDate"}
                    }
                ],
                // 用户数据
                data: [],
                thisDom: undefined
            }
        },
        created() {
            this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
            this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
            this._signCardRecordServiceProxy = new SignCardRecordServiceProxy(this.$apiUrl, this.$api);
            this._UserServiceProxy = new UserServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            _this = this;
            this.companyTreeInit();
            if (abpService.abp.userInfo.companyId) {
                this.comPanyId = abpService.abp.userInfo.companyId;
                this.handleChange(this.comPanyId);
            }
        },
        methods: {
            searchTree(inputValue, treeNode) {
                return treeNode.data.props.title.includes(inputValue)
            },
            companyTreeInit() {
                this.spinning = true;
                this._CompanyConfigServiceProxy
                    .getPaged(undefined, "", 100, 0)
                    .finally(() => {
                        this.spinning = false;
                    })
                    .then((res) => {
                        this.companyList = res.items;
                        this.companyList.forEach((item) => {
                            if (item.parentId == 0) {
                                var obj = {
                                    key: item.id,
                                    value: item.id,
                                    title: item.fullName
                                };
                                this.companyTreeList.push(obj);

                            }
                        });
                        this.initCompanyTree(this.companyTreeList);
                        if (this.companyTreeList.length > 0) {
                            this.handleChange(this.companyTreeList[0].key);
                        }

                    });
            },

            /**
             * 初始化树形结构数据格式
             */
            initCompanyTree(list) {
                list.forEach((v) => {
                    //遍历父级数据，加载子集=
                    v.children = []; //创建空的子集
                    this.companyList.forEach((m) => {
                        if (m.parentId == v.value) {
                            //根据主键查子集数据
                            var obj = {
                                key: m.id,
                                value: m.id,
                                title: m.fullName
                            };
                            v.children.push(obj); //放进父级的集合中
                            this.initCompanyTree(v.children); //递归查询子集的集合
                        }
                    });
                    if (v.children.length == 0) {
                        //校验如何数据没有子集，删除子集参数
                        delete v.children;
                    }
                });
            },

            //公司下拉框
            handleChange(value) {
                this.comPanyId = value;
                this.pageNumber = 1;
                this.request.skipCount = 0;

                this.departmentId = undefined;

                this.getUserData();
                this.getDepTreeData();
            },
            deptChange(value) {
                this.pageNumber = 1;
                this.request.skipCount = 0;
                this.departmentId = value;
                this.getUserData();
            },
            /**
             * 获取部门
             */
            getDepTreeData() {
                this.DepTreeData = [];
                this._DepartmentServiceProxy.getDepTreeData(this.comPanyId).then(res => {
                    this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
                    this.DepTree.forEach(item => {
                        if (!item.parentId) {
                            this.DepTreeData.push(item);
                        }
                    });
                    this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);

                }).finally(() => {
                    this.loading = false;
                })
            },
            /**
             * 选择部门
             */
            SelectDepartmentTree(e) {
                this.departmentId = e;
            },

            /**
             * 拉取数据
             */
            getUserData() {
                this.spinning = true;
                this._signCardRecordServiceProxy
                    .getPageUserList(
                        this.comPanyId,
                        this.departmentId,
                        undefined,
                        this.filterText,
                        this.request.sorting,
                        this.request.maxResultCount,
                        this.request.skipCount
                    )
                    .finally(() => {
                        this.spinning = false;
                    })
                    .then(result => {
                        this.data = result.items;
                        this.data.map(item => {
                            item.hireDate = item.hireDate ? moment(item.hireDate).format('YYYY-MM-DD') : "";
                        })
                        this.totalItems = result.totalCount;
                        this.totalPages = Math.ceil(
                            result.totalCount / this.request.maxResultCount
                        );
                        this.pagerange = [
                            (this.pageNumber - 1) * this.request.maxResultCount + 1,
                            this.pageNumber * this.request.maxResultCount
                        ];
                        this.$emit("GetUserCount", result.totalCount)
                    });
            },
            tablehandleChange(pagination, filters, sorter) {
                this.filteredInfo = filters;
                this.sortedInfo = sorter;
            },
            /**
             * 筛选查询
             */
            fileChange() {
                this.pageNumber = 1;
                this.request.skipCount = 0;
                this.getUserData();
            },
            /**
             * 分页事件
             */
            showTotalFun() {
                return this.l(
                    "GridFooterDisplayText",
                    this.pageNumber,
                    this.totalPages,
                    this.totalItems,
                    this.pagerange[0],
                    this.pagerange[1]
                );
            },
            showSizeChange(current, size) {
                this.pageNumber = 1;
                this.request.skipCount = 0;
                this.request.maxResultCount = size;
                this.getUserData();
            },
            /**
             * 分页
             */
            onChange(page, pageSize) {
                this.selectedRowKeys = [];
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getUserData();
            },
            /**
             * 排序
             * @param pagination
             * @param filters
             * @param sorter
             */
            sorterChange(pagination, filters, sorter) {
                if (sorter.order) {
                    this.request.sorting = sorter.columnKey + " " + sorter.order.replace('end', '');
                } else {
                    this.request.sorting = undefined;
                }
                this.getUserData();

            },
            /**
             * 表格行点击
             * @param record 点击行的数据
             * @param index 点击行的下标
             */
            customRow: (record, index) => ({
                on: {
                    click: (e) => {
                        // _this.thisDom=e.toElement.parentElement;
                        // _this.thisDom.style.backgroundColor="#E6F7FF";
                        ModalHelper.create(LeaveCardOvertimeDetailedPage,
                            {
                                user: record
                            },
                            {
                                width: "80%"
                            }).subscribe(res => {
                            // _this.thisDom.style.backgroundColor="";
                        })
                    }
                }
            }),
        }
    }
</script>

<style scoped>
    .pagination {
        margin: 10px auto;
        text-align: right;
    }

    >>> .ant-table-row {
        cursor: pointer;
    }
</style>
