<template>
    <a-spin :spinning="spinning">
        <!-- 标题 -->
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="user"/>
                <span>{{ user.realName }}({{user.jobNumber}})</span>
            </div>
        </div>
        <div class="my-md">
            <a-tabs default-active-key="1" @change="callback">
                <a-tab-pane key="1" tab="请假明细" forceRender>
                    <leave-record :userId="user.id" ref="leave" ></leave-record>
                </a-tab-pane>
                <a-tab-pane key="2" tab="加班明细" forceRender>
                    <over-time-record :userId="user.id" ref="over" ></over-time-record>
                </a-tab-pane>
                <a-tab-pane key="3" tab="补卡明细" forceRender>
                    <sign-card-record :userId="user.id" ref="sign" ></sign-card-record>
                </a-tab-pane>
            </a-tabs>
        </div>
    </a-spin>
</template>

<script>
    import ModalComponentBase from "../../../../shared/component-base/modal-component-base";
    import LeaveRecord from './leave-record/leave-record'
    import OverTimeRecord from './overtime-record/overtime-record'
    import SignCardRecord from './signcard-record/signcard-record'

    export default {
        name: "leave-card-overtime-detailed-page",
        mixins: [ModalComponentBase],
        components: {LeaveRecord, OverTimeRecord, SignCardRecord},
        data() {
            return {
                spinning: false,
                user: undefined
            }
        },
        created() {
            this.fullData();
        },
        mounted() {
        },
        methods: {
            callback(v) {
                switch (v) {
                    case "1":
                        this.$refs.leave.getData();
                        break;
                    case "2":
                        this.$refs.over.getData();
                        break;
                    case "3":
                        this.$refs.sign.getData();
                        break;
                }
            }
        }
    }
</script>

<style scoped>

</style>
