<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="spinning">
			<!-- 标题 -->
			<div class="modal-header">
				<div class="modal-title">
					<a-icon type="medicine-box" />
					<span v-if="id">{{ l('编辑补卡明细') }}</span>
					<span v-else>{{ l('新增补卡明细') }}</span>
				</div>
			</div>
			<div class="my-md">
				<a-row>
					<a-col :span="4">
						<q-label required :label="l('ErrorTime')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-date-picker
							style="width: 100%"
							format="YYYY-MM-DD"
							valueFormat="YYYY-MM-DD"
							v-model="entity.attDate"
						/>
					</a-col>
					<a-col :span="4">
						<q-label required :label="l('SignCardTime')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-date-picker
							style="width: 100%"
							format="YYYY-MM-DD HH:mm"
							valueFormat="YYYY-MM-DD HH:mm"
							:showTime="{ format: 'HH:mm' }"
							v-model="entity.checkTime"
						/>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label required :label="l('SignCardType')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-select
							show-search
							:placeholder="l('SignCardType')"
							option-filter-prop="children"
							style="width: 100%"
							v-model="entity.signCardType"
						>
							<a-select-option v-for="item in SignCardTypeList" :key="item.itemDetailCode">
								{{ item.itemDetailName }}
							</a-select-option>
						</a-select>
					</a-col>
					<a-col :span="4">
						<q-label required :label="l('SignCardReason')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-select
							show-search
							:placeholder="l('SignCardReason')"
							option-filter-prop="children"
							style="width: 100%"
							v-model="entity.reasonType"
						>
							<a-select-option v-for="item in SignCardReasonList" :key="item.itemDetailCode">
								{{ item.itemDetailName }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('Remark')"> </q-label>
					</a-col>
					<a-col :span="20">
						<a-textarea :placeholder="l('Remark')" v-model="entity.reason" :rows="4" />
					</a-col>
				</a-row>
			</div>
			<!-- 按钮 -->
			<div class="modal-footer">
				<a-button @click="close" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :type="'primary'" @click="save">
					<a-icon type="save" />
					{{ l('Save') }}
				</a-button>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import ModalComponentBase from '../../../../../../shared/component-base/modal-component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { Dic } from '../../../../../../shared/utils';
import {
	CreateOrUpdateSignCardRecordInput,
	SignCardRecordEditDto,
	SignCardRecordServiceProxy,
} from '../../../../../../shared/service-proxies';
import moment from 'moment';

export default {
	name: 'create-or-edit-signcard-record',
	mixins: [ModalComponentBase],
	data() {
		return {
			spinning: false,
			zh_CN,
			id: undefined,
			userId: undefined,
			SignCardTypeList: [],
			SignCardReasonList: [],
			entity: new SignCardRecordEditDto(),
		};
	},
	created() {
		this.fullData();
		this._signCardRecordServiceProxy = new SignCardRecordServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		this.SignCardTypeList = await Dic.getInstance().getDicAsync('SignCardType');
		this.SignCardReasonList = await Dic.getInstance().getDicAsync('ReasonType');
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		getData() {
			this.spinning = true;
			this._signCardRecordServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.entity = res.signCardRecord;
				});
		},
		save() {
			if (!this.entity.attDate) {
				return abp.message.warn('请选择异常出勤日期');
			}
			if (!this.entity.checkTime) {
				return abp.message.warn('请选择补卡时间');
			}
			if (!this.entity.signCardType) {
				return abp.message.warn('请选择补卡类别');
			}
			if (!this.entity.reasonType) {
				return abp.message.warn('请选择补卡原因');
			}
			this.entity.attDate = moment(this.entity.attDate);
			this.entity.checkTime = moment(this.entity.checkTime);
			this.entity.empId = this.userId;
			let input = new CreateOrUpdateSignCardRecordInput();
			input.signCardRecord = this.entity;
			this.spinning = true;
			this._signCardRecordServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped></style>
