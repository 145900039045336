<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="spinning">
			<!-- 标题 -->
			<div class="modal-header">
				<div class="modal-title">
					<a-icon type="medicine-box" />
					<span v-if="id">{{ l('编辑加班明细') }}</span>
					<span v-else>{{ l('新增加班明细') }}</span>
				</div>
			</div>
			<div class="my-md">
				<a-row>
					<a-col :span="4">
						<q-label required :label="l('OverTimeType')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-select
							show-search
							:placeholder="l('OverTimeType')"
							option-filter-prop="children"
							style="width: 100%"
							v-model="entity.overTimeType"
						>
							<a-select-option v-for="item in OverTimeTypeList" :key="item.itemDetailCode">
								{{ item.itemDetailName }}
							</a-select-option>
						</a-select>
					</a-col>
					<a-col :span="4">
						<q-label required :label="l('Declare')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-select
							show-search
							:placeholder="l('Declare')"
							option-filter-prop="children"
							style="width: 100%"
							v-model="entity.declareWay"
						>
							<a-select-option v-for="item in DeclareWayList" :key="item.itemDetailCode">
								{{ item.itemDetailName }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label required :label="l('OverTimeStartTime')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-date-picker
							style="width: 100%"
							format="YYYY-MM-DD HH:mm"
							valueFormat="YYYY-MM-DD HH:mm"
							:showTime="{ format: 'HH:mm' }"
							v-model="entity.startTime"
						/>
					</a-col>
					<a-col :span="4">
						<q-label required :label="l('OverTimeEndTime')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-date-picker
							style="width: 100%"
							format="YYYY-MM-DD HH:mm"
							valueFormat="YYYY-MM-DD HH:mm"
							:showTime="{ format: 'HH:mm' }"
							v-model="entity.endTime"
						/>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label required :label="l('OverTimeTimeNumber')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number
							:placeholder="l('OverTimeTimeNumber')"
							v-model="entity.overTimeHours"
							style="width: 100%"
							:min="0"
						/>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('OverTimeShiftCode')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-select
							show-search
							:placeholder="l('OverTimeShiftCode')"
							option-filter-prop="children"
							style="width: 100%"
							v-model="entity.shiftCode"
						>
							<a-select-option v-for="item in ShiftCodeList" :key="item.code">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label required :label="l('出勤日期')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-date-picker style="width: 100%" v-model="entity.shiftDate" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('Remark')"> </q-label>
					</a-col>
					<a-col :span="20">
						<a-textarea :placeholder="l('Remark')" v-model="entity.reason" :rows="4" />
					</a-col>
				</a-row>
			</div>
			<!-- 按钮 -->
			<div class="modal-footer">
				<a-button @click="close" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :type="'primary'" @click="save">
					<a-icon type="save" />
					{{ l('Save') }}
				</a-button>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import ModalComponentBase from '../../../../../../shared/component-base/modal-component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { Dic } from '../../../../../../shared/utils';
import {
	GetOverTimeRecordForEditOutput,
	GetShiftInfoForEditOutput,
	OverTimeRecordEditDto,
	OverTimeRecordServiceProxy,
} from '../../../../../../shared/service-proxies';
import moment from 'moment';

export default {
	name: 'create-or-edit-overtime-record',
	mixins: [ModalComponentBase],
	data() {
		return {
			spinning: false,
			zh_CN,
			id: undefined,
			OverTimeTypeList: [],
			DeclareWayList: [],
			ShiftCodeList: [],
			entity: new OverTimeRecordEditDto(),
			userId: undefined,
		};
	},
	created() {
		this.fullData();
		this._overTimeRecordServiceProxy = new OverTimeRecordServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		this.OverTimeTypeList = await Dic.getInstance().getDicAsync('QYB_OverTimeType');
		this.DeclareWayList = await Dic.getInstance().getDicAsync('DeclareWayType');
		this.ShiftCodeList = await this.getShiftCode();
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		getData() {
			this.spinning = true;
			this._overTimeRecordServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.entity = res.overTimeRecord;
				});
		},
		/**
		 * 获取班次
		 * @returns {Promise<unknown>}
		 */
		getShiftCode() {
			return new Promise((resolve, reject) => {
				this.spinning = true;
				this._overTimeRecordServiceProxy
					.getShiftInfoList()
					.finally(() => {
						this.spinning = false;
					})
					.then((res) => {
						resolve(res);
					});
			});
		},
		save() {
			if (!this.entity.overTimeType) {
				return abp.message.warn('请选择加班类型');
			}
			if (!this.entity.declareWay) {
				return abp.message.warn('请选择申报方式');
			}
			if (!this.entity.startTime) {
				return abp.message.warn('请选择开始日期');
			}
			if (!this.entity.endTime) {
				return abp.message.warn('请选择结束日期');
			}
			if (this.entity.overTimeHours != 0 && !this.entity.overTimeHours) {
				return abp.message.warn('请输入加班时数');
			}
			if (this.entity.overTimeHours <= 0) {
				return abp.message.warn('加班时数必须大于0');
			}
			this.entity.startTime = moment(this.entity.startTime);
			this.entity.endTime = moment(this.entity.endTime);
			if (this.entity.startTime > this.entity.endTime) {
				return abp.message.warn('开始日期不能大于结束日期');
			}
			if (!this.entity.shiftDate) {
				return abp.message.warn('请选择出勤日期');
			}
			this.entity.empId = this.userId;
			let input = new GetOverTimeRecordForEditOutput();
			input.overTimeRecord = this.entity;
			this.spinning = true;
			this._overTimeRecordServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped></style>
