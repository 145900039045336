<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="spinning">
			<!--            筛选条件，按钮-->
			<div class="my-md">
				<a-row>
					<a-col :span="16">
						<a-row>
							<a-col :span="3" style="text-align: right;padding-right: 3px">
								{{ l('OverTimeType') }}:
							</a-col>
							<a-col :span="5">
								<a-select
									show-search
									:placeholder="l('OverTimeType')"
									option-filter-prop="children"
									style="width: 100%"
									allowClear
									@change="searchChange"
									v-model="OverTimeTypeCode"
								>
									<a-select-option v-for="item in OverTimeTypeList" :key="item.itemDetailCode">
										{{ item.itemDetailName }}
									</a-select-option>
								</a-select>
							</a-col>
							<a-col :span="3" style="text-align: right;padding-right: 3px">
								申报方式:
							</a-col>
							<a-col :span="4">
								<a-select
									show-search
									:placeholder="l('OverTimeType')"
									option-filter-prop="children"
									style="width: 100%"
									allowClear
									@change="searchChange"
									v-model="DeclareWayCode"
								>
									<a-select-option v-for="item in DeclareWayList" :key="item.itemDetailCode">
										{{ item.itemDetailName }}
									</a-select-option>
								</a-select>
							</a-col>
							<a-col :span="3" style="text-align: right;padding-right: 3px">
								出勤日期:
							</a-col>
							<a-col :span="6">
								<a-range-picker @change="onTimeChange" />
							</a-col>
						</a-row>
					</a-col>
					<a-col :span="8" style="text-align: right">
						<a-button :type="'primary'" @click="Create()" v-if="isGranted('overtime_record_add')">
							<a-icon type="plus" />
							<span>{{ l('Create') }}</span>
						</a-button>
						<a-button :type="'primary'" @click="Edit()" v-if="isGranted('overtime_record_edit')">
							<a-icon type="edit" />
							<span>{{ l('Edit') }}</span>
						</a-button>
						<a-button :type="'danger'" @click="BatchDelete()" v-if="isGranted('overtime_record_delete')">
							<a-icon type="delete" />
							<span>{{ l('Delete') }}</span>
						</a-button>
						<a-button :type="'primary'" @click="Export()" v-if="isGranted('overtime_record_excel')">
							<a-icon type="download" />
							<span>{{ l('Export') }}</span>
						</a-button>
					</a-col>
				</a-row>
			</div>
			<div class="my-md">
				<a-table
					:customRow="customRow"
					:row-selection="{
						selectedRowKeys: selectedRowKeys,
						onSelectAll: tableSelectAll,
						onSelect: tableonSelect,
					}"
					:columns="columns"
					:pagination="false"
					:rowKey="(data) => data.id"
					:dataSource="tableData"
				>
				</a-table>
				<a-pagination
					class="pagination"
					:total="totalItems"
					v-model="pageNumber"
					showSizeChanger
					showQuickJumper
					:showTotal="showTotalFun"
					@change="onChange"
					@showSizeChange="showSizeChange"
				/>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import { AppComponentBase } from '../../../../../shared/component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import * as _ from 'lodash';
import ModalHelper from '../../../../../shared/helpers/modal/modal-helper';
import CreateOrEditOverTimeRecord from './create-or-edit-overtime-record/create-or-edit-overtime-record';
import { Dic, fileDownloadService } from '../../../../../shared/utils';
import { OverTimeRecordServiceProxy } from '../../../../../shared/service-proxies';
import moment from 'moment';

let _this;
export default {
	name: 'overtime-record',
	mixins: [AppComponentBase],
	components: { CreateOrEditOverTimeRecord },
	props: {
		userId: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			spinning: false,
			zh_CN,
			OverTimeTypeList: [],
			OverTimeTypeCode: undefined,
			DeclareWayList: [],
			DeclareWayCode: undefined,
			// 总数
			totalItems: 0,
			// 当前页码
			pageNumber: 1,
			// 共多少页
			totalPages: 1,
			// 条数显示范围
			pagerange: [1, 1],
			// 显示条数
			pageSizeOptions: ['10', '20', '30', '40'],
			request: { maxResultCount: 10, skipCount: 0 },
			selectedRowKeys: [],
			// 用户表格
			columns: [
				{
					title: this.l('OverTimeType'),
					dataIndex: 'overTimeType',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'overTimeType' },
				},
				{
					title: this.l('出勤日期'),
					dataIndex: 'shiftDate',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'shiftDate' },
				},
				{
					title: this.l('OverTimeStartTime'),
					dataIndex: 'startTime',
					sorter: false,
					width: 150,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'startTime' },
				},
				{
					title: this.l('OverTimeEndTime'),
					dataIndex: 'endTime',
					sorter: false,
					ellipsis: true,
					width: 150,
					align: 'center',
					scopedSlots: { customRender: 'endTime' },
				},
				{
					title: this.l('OverTimeTimeNumber'),
					dataIndex: 'overTimeHours',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'overTimeHours' },
				},
				{
					title: this.l('Declare'),
					dataIndex: 'declareWay',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'declareWay' },
				},
				{
					title: this.l('OverTimeShiftCode'),
					dataIndex: 'shiftCode',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'shiftCode' },
				},
				{
					title: this.l('OverTimeReason'),
					dataIndex: 'reason',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'reason' },
				},
			],
			// 用户数据
			tableData: [],
			selectRows: [],
			startTime: undefined,
			endTime: undefined,
		};
	},
	created() {
		this._overTimeRecordServiceProxy = new OverTimeRecordServiceProxy(this.$apiUrl, this.$api);
		this._fileDownloadService = fileDownloadService;
		this.columns = this.getColumn('加班明细', this.columns);
	},
	async mounted() {
		_this = this;
		this.OverTimeTypeList = await Dic.getInstance().getDicAsync('QYB_OverTimeType');
		this.DeclareWayList = await Dic.getInstance().getDicAsync('DeclareWayType');
		this.getData();
	},
	methods: {
		searchChange() {
			this.request.skipCount = 0;
			this.pageNumber = 1;
			this.getData();
		},
		getData() {
			this.spinning = true;
			this.selectRows = [];
			this.selectedRowKeys = [];
			this._overTimeRecordServiceProxy
				.getPaged(
					this.userId,
					undefined,
					undefined,
					this.OverTimeTypeCode,
					this.DeclareWayCode,
					this.startTime,
					this.endTime,
					undefined,
					undefined,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.tableData.map((item) => {
						item.checked = false;
						item.startTime = moment(item.startTime).format('YYYY-MM-DD HH:mm');
						item.endTime = moment(item.endTime).format('YYYY-MM-DD HH:mm');
						item.shiftDate = moment(item.shiftDate).format('YYYY-MM-DD');
					});
					this.totalItems = res.totalCount;
					this.totalPages = Math.ceil(res.totalCount / this.request.maxResultCount);
					this.pagerange = [
						(this.pageNumber - 1) * this.request.maxResultCount + 1,
						this.pageNumber * this.request.maxResultCount,
					];
				});
		},
		/**
		 * 表格行点击
		 * @param record 点击行的数据
		 * @param index 点击行的下标
		 */
		customRow: (record, index) => ({
			on: {
				click: () => {
					record.checked = !record.checked;
					if (record.checked) {
						//判断是否存在
						let u = _this.selectRows.find((item) => item.id == record.id);
						if (!u) {
							_this.selectedRowKeys.push(record.id);
							_this.selectRows.push(record);
						}
					} else {
						_this.selectedRowKeys = [..._this.selectedRowKeys.filter((item) => item != record.id)];
						_this.selectRows = [..._this.selectRows.filter((item) => item.id != record.id)];
					}
				},
			},
		}),
		tableSelectAll(record, rows, crows) {
			if (record) {
				//更改表格数据勾选状态
				this.tableData.map((item) => {
					item.checked = true;
				});
				rows.forEach((item, index) => {
					//判断是否存在
					let u = this.selectRows.find((t) => t.id == item.id);
					if (!u) {
						this.selectRows.push(item);
						this.selectedRowKeys.push(item.id);
					}
				});
			} else {
				//更改表格数据勾选状态
				this.tableData.forEach((item, index) => {
					item.checked = false;
					this.selectRows = this.selectRows.filter((t) => t.id != item.id);
					this.selectedRowKeys = _.difference(this.selectedRowKeys, [item.id]);
				});
			}
		},
		/**
		 * 重写复选框点击事件(不可删除)
		 */
		tableonSelect(record) {
			record.checked = !record.checked;
			if (record.checked) {
				this.selectedRowKeys.push(record.id);
				this.selectRows.push(record);
			} else {
				this.selectRows = [...this.selectRows.filter((item) => item.id != record.id)];
				this.selectedRowKeys = [...this.selectedRowKeys.filter((item) => item != record.id)];
			}
		},
		/**
		 * 分页事件
		 */
		showTotalFun() {
			return this.l(
				'GridFooterDisplayText',
				this.pageNumber,
				this.totalPages,
				this.totalItems,
				this.pagerange[0],
				this.pagerange[1]
			);
		},
		/**
		 * 分页
		 */
		onChange(page, pageSize) {
			this.pageNumber = page;
			this.request.skipCount = (page - 1) * this.request.maxResultCount;
			this.getData();
		},
		showSizeChange(current, size) {
			// this.pageNumber = 1;
			this.request.maxResultCount = size;
			this.searchChange();
		},
		Create() {
			ModalHelper.create(
				CreateOrEditOverTimeRecord,
				{
					userId: this.userId,
				},
				{
					width: '600px',
				}
			).subscribe((res) => {
				if (res) {
					this.getData();
				}
			});
		},
		Edit() {
			if (this.selectRows.length <= 0) {
				return abp.message.warn('请至少选择一项');
			}
			if (this.selectRows.length > 1) {
				return abp.message.warn('只能选择一项');
			}
			ModalHelper.create(
				CreateOrEditOverTimeRecord,
				{
					userId: this.userId,
					id: this.selectRows[0].id,
				},
				{
					width: '600px',
				}
			).subscribe((res) => {
				if (res) {
					this.getData();
				}
			});
		},
		BatchDelete() {
			if (this.selectRows.length <= 0) {
				return abp.message.warn('请至少选择一项');
			}
			this.$confirm({
				title: '确认信息',
				okText: '确认',
				cancelText: '取消',
				content: '您确认要删除这' + this.selectRows.length + '条数据吗?',
				onOk: () => {
					this.spinning = true;
					let ids = _.map(this.selectRows, 'id');
					this._overTimeRecordServiceProxy
						.batchDelete(ids)
						.finally(() => {
							this.spinning = false;
						})
						.then((res) => {
							this.$notification['success']({
								message: this.l('SuccessfullyDeleted'),
							});
							this.request.skipCount = 0;
							this.pageNumber = 1;
							this.getData();
						});
				},
			});
		},
		onTimeChange(record) {
			if (record.length > 0) {
				this.startTime = moment(moment(record[0]).format('YYYY-MM-DD'));
				this.endTime = moment(moment(record[1]).format('YYYY-MM-DD'));
			} else {
				this.startTime = undefined;
				this.endTime = undefined;
			}
			this.request.skipCount = 0;
			this.pageNumber = 1;
			this.getData();
		},
		/**
		 * 导出表格
		 * @constructor
		 */
		Export() {
			this._overTimeRecordServiceProxy
				.getToExcelFile(
					this.userId,
					undefined,
					undefined,
					this.OverTimeTypeCode,
					this.DeclareWayCode,
					this.startTime,
					this.endTime,
					undefined,
					undefined,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.then((res) => {
					this._fileDownloadService.downloadTempFile(res);
				});
		},
	},
};
</script>

<style scoped>
.pagination {
	margin: 10px auto;
	text-align: right;
}
</style>
